.success-popup {
  position: fixed;
  top: 50%;
  left: calc(50% + 50px);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #000000;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
}
